<template>
  <div class="mr-6">
    <div
      class="d-flex justify-space-between mt-4"
      :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '460px' }"
      height="80px"
    >
      <div class="d-flex flex-column">
        <p class="body-bold">{{ address.cep }}</p>
        <p class="body-text my-1">{{ address.logradouro }}</p>
        <p class="body-text">
          {{ address.bairro }}, {{ address.localidade }} -
          {{ address.uf }}
        </p>
      </div>
      <div class="d-flex align-center" style="min-width: 75px">
        <a class="body-text text-uppercase" @click="$emit('click:use-cep')">{{
          $t("guest.use_cep")
        }}</a>
      </div>
    </div>
    <div class="mt-4" style="border-bottom: 1px solid #ddd; width: 100%" />
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {}
  },
}
</script>

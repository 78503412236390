<template>
  <z-card width="100%" style="height: 100%" :title="$t('guest.search_zipcode')">
    <template #content>
      <div class="d-flex flex-column mb-5">
        <div v-for="(address, i) in addressResults" :key="i">
          <address-card
            :address="address"
            @click:use-cep="onClickUseCep(address)"
          />
        </div>
      </div>

      <z-btn
        width="100%"
        :text="$t('guest.back')"
        @click="
          $router.push({
            name: 'forgot-address',
          })
        "
      />
    </template>
  </z-card>
</template>

<script>
import { useRouter } from "vue-router/composables"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"

import AddressCard from "./AddressCard.vue"

export default {
  components: {
    AddressCard,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { addressResults } = useNamespacedState("parameters", [
      "addressResults",
    ])

    const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])
    const { tryFillByZipCode } = useStoreAction("guest", "tryFillByZipCode")

    const router = useRouter()

    const onClickUseCep = async (address) => {
      changeGuest({
        zipCode: address.cep,
      })
      await tryFillByZipCode()
      router.push({ name: "personal-info", query: "address" })
    }

    return {
      addressResults,
      onClickUseCep,
    }
  },
}
</script>
